<!-- 使用记录 -->

<template>
  <w-dialog class="usage-record" ref="usageRef" title="使用记录" width="50%" top="5vh" :hideFooter="true">
    <div class="usage-content" v-loading="usageLoading">
      <common-table ref="tableRef" tableHeight="calc(100vh - 550px)" :ischeck="false"
        :apiName="ScenicApi.getMemberCardLogList" :columns="tableColumns"
        :extraParame="{ mcd_id: currentMember.mcd_id}"></common-table>
    </div>
  </w-dialog>
</template>
<script>
  import { ref, nextTick, } from "vue";
  import { ScenicApi, } from "@/plugins/api.js";

  export default {
    setup() {
      const usageRef = ref(null);  // 使用记录对象
      const usageLoading = ref(false);  // 加载状态
      const currentMember = ref({});  // 当前行会员数据
      const tableRef = ref(null);  // 表格对象
      const tableColumns = ref([  // 表格配置数据
        {
          prop: "mcl_date",
          label: "使用日期",
        },
        {
          prop: "mcl_count",
          label: "使用次数",
        },
        {
          prop: "mcl_ctime",
          label: "记录时间",
        },
      ]);
      /**
       * 
       * 打开弹框
       * 
       * **/
      const openDialog = (row) => {
        currentMember.value = row;
        usageRef.value.show();
        nextTick(() => {
          tableRef.value.tableLoad();
        });
      }

      return {
        usageRef,
        usageLoading,
        openDialog,
        currentMember,
        tableColumns,
        tableRef,
        ScenicApi,
      };
    },
  };
</script>

<style lang="scss">
  .usage-record {}
</style>
<!-- 销售管理-会员卡 -->

<template>
  <div class="vip-container main-cnt">
    <div class="title">会员卡列表</div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="ScenicApi.getMembershipCardLists" :columns="tableColumns" @typeChange="typeChange">
        <template #operate>
          <el-button type="primary" round @click="registerBtn"
            v-if="authData.indexOf('m_jPzqxYueAFwzXcbZwvMBMo9ZWw') != -1">快捷录入</el-button>
        </template>

        <template #activation="{ row }">
          <el-button v-if="row.use_status == '6' && authData.indexOf('m_2STpZ4IQEepFCbnYx4XiR4RTe') != -1"
            @click="onActivation(row)">激活</el-button>
        </template>

        <template #registerBtn="{ row }">
          <el-button v-if="row.use_status == '1' && authData.indexOf('m_2STpZ4IQEepFCbnYx4XiR4RTe') != -1"
            @click="registerBtn(row)">录入通行证</el-button>
        </template>

        <template #usageRecord="{ row }">
          <el-button v-if="row.use_status != '6' && authData.indexOf('m_DngmHP06LwSTXMRz3ASdbOG1F597') != -1"
            @click="onUsageRecord(row)">使用记录</el-button>
        </template>
      </common-table>
    </div>

    <!-- 激活 -->
    <w-dialog ref="activationDialog" class="enter-dialog" title="激活" width="40%" btnWidth="140px" top="10vh"
      confirmText="确认激活" @wConfirm="onConfirmActivation">
      <div class="ticket-content">
        <div class="data-title">激活日期</div>
        <el-date-picker v-model="s_time" type="date" placeholder="请选择日期" value-format="YYYY-MM-DD">
        </el-date-picker>

        <div v-if="birthdayCount.length > 0">
          <div class="data-title">生日日期</div>
          <el-row>
            <el-col :span="8" v-for="(item, i) in birthdayCount" :key="i" style="margin-right: 10px;">
              <el-date-picker v-model="item.child_birthday" type="date" placeholder="请选择日期" value-format="YYYY-MM-DD">
              </el-date-picker>
            </el-col>
          </el-row>
        </div>

        <div class="upload-box" v-if="showUpload">
          <el-dropdown>
            <div class="into-face">录入人脸</div>
            <template #dropdown>
              <el-dropdown-menu class="face-dropdown-menu">
                <el-dropdown-item class="face-dropdown-item">
                  <imgUpload uploadTitle="人脸照片" uploadText="本地上传" @uploadFile="uploadFile($event)" :noReplaceImg="false"
                    @uploadLoading="uploadLoading"></imgUpload>
                </el-dropdown-item>
                <el-dropdown-item @click="openFaceDialog(index)" class="face-dropdown-item">拍照录入
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <div class="img-container flex flex-wrap" v-if="facialPhotos.length > 0">
            <div class="face-img" v-for="(imgs, i) in facialPhotos" :key="i">
              <el-image style="width: 100px; height: 100px" :src="imgs.standard_url" :preview-src-list="srcList"
                fit="cover">
              </el-image>
              <span class="del" @click="deleteFile(imgs, i)">
                <el-icon>
                  <Close />
                </el-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
    </w-dialog>

    <!-- 录入通行证 -->
    <w-dialog ref="ticketDialog" class="enter-dialog" title="录入信息" width="60%" btnWidth="140px" top="6vh"
      confirmText="确认录入" @wConfirm="confirm">
      <div class="ticket-content">
        <img-upload uploadTitle="" suggestText="" :fileList="fileList" :isShowUpload="false" :noReplaceImg="false">
        </img-upload>
        <div class="enter-title">录入日期：{{ dateDay }}</div>
        <div v-if="isQuick">
          <el-select v-model="s_id" placeholder="请选择园区" clearable>
            <el-option v-for="el in parkOptions" :key="el.s_id" :label="el.s_name" :value="el.s_id"></el-option>
          </el-select>
        </div>
        <div class="enter-title" v-if="childrenInfo.length > 0">儿童信息录入</div>
        <div class="visitor-item">
          <el-form label-position="right" class="visitor-form">
            <el-form-item prop="paper_sn" label="" v-for="(el, index) in childrenInfo" :key="index">
              <el-input v-model="el.paper_sn" autocomplete="off" placeholder="请用扫码枪识别手环码" :id="'children' + index"
                @keyup.enter="handleSn('children', index, index + 1)" clearable @blur="childrenBlur" />
            </el-form-item>
          </el-form>
        </div>
        <div class="add-btn" v-if="isQuick" @click="onAddLineBtn('children')">
          <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
        </div>

        <div class="enter-title" v-if="adultInfo.length > 0">成人信息录入</div>
        <div class="visitor-item">
          <el-form label-position="right" class="visitor-form">
            <el-form-item prop="paper_sn" label="" v-for="(el, index) in adultInfo" :key="index">
              <el-input v-model="el.paper_sn" autocomplete="off" placeholder="请用扫码枪识别手环码" :id="'adult' + index"
                @keyup.enter="handleSn('adult', index, index + 1)" clearable @blur="childrenBlur" />
            </el-form-item>
          </el-form>
        </div>
        <div class="add-btn" v-if="isQuick" @click="onAddLineBtn('adult')">
          <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
        </div>
      </div>
    </w-dialog>

    <!-- 拍照上传 -->
    <FaceDialog ref="faceDialogRef" @submit="handleCamera"></FaceDialog>

    <!-- 录入通行证 -->
    <EntryPass ref="entryPassRef" @determine="determine"></EntryPass>

    <!-- 使用记录 -->
    <UsageRecord ref="usageRecordRef"></UsageRecord>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch } from "vue";
  import { BasicApi, ScenicApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import imgUpload from "@/components/img-upload/img-upload.vue";
  import { useStore } from "vuex";
  import FaceDialog from "../../scenic/components/FaceDialog.vue";
  import { Close } from "@element-plus/icons-vue";
  import dayjs from "dayjs";
  import EntryPass from "../../scenic/components/EntryPass.vue";
  import UsageRecord from "../../scenic/components/UsageRecord.vue";
  import { duplicates, } from "@/utils/common.js";

  const store = useStore();
  const authData = ref([]);  // 权限
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const dateDay = ref(dayjs(new Date()).format("YYYY-MM-DD"));  // 当天日期
  const tableRef = ref(null);  // 表格对象
  const parkOptions = ref([]);  // 园区选项
  const ticketDialog = ref(null);
  const mcd_id = ref(null);  // 录入会员卡id
  const childrenInfo = ref([]);  // 儿童信息
  const adultInfo = ref([]);  // 成人信息
  const fileList = ref([]);  // 人脸数据
  const filters = ref([
    {
      filterType: "searchKey",
      name: "keywords",
      value: "",
      placeholder: "搜索会员卡编号、姓名、手机号",
    },
    {
      filterType: "select",
      name: "type",
      value: "",
      placeholder: "请选择会员卡类型",
      options: [],
      val: "stt_id",
      lab: "stt_name",
      action: "typeChange",
    },
    {
      filterType: "select",
      name: "mcd_name",
      value: "",
      placeholder: "请选择资源名称",
      options: [],
      val: "name",
      lab: "name",
    },
    {
      filterType: "select",
      name: "status",
      value: "",
      placeholder: "请选择状态",
      options: [],
      val: "id",
      lab: "name",
    },
		{
      filterType: "date",
      type: "daterange",
			name: "start_time",
      name2: "end_time",
			value: [],
      rangeText: "-",
		},
  ]);
  const tableColumns = ref([  // 表格配置数据
    {
      prop: "mcd_sn",
      label: "会员卡ID",
      color: "--text-third-color",
      minWidth: 150,
    },
    {
      prop: "mcd_name",
      label: "名称",
      minWidth: 150,
    },
    {
      prop: "type_name",
      label: "类型",
    },
    {
      prop: "m_mobile",
      label: "联系方式",
      minWidth: 120,
    },
    {
      prop: "use_status_msg",
      label: "状态",
    },
    {
      prop: "enable_total_count",
      label: "总可用次数",
      minWidth: 100,
    },
    {
      prop: "enable_day_count",
      label: "当天可用次数",
      minWidth: 110,
    },
    {
      prop: "mcd_create_time",
      label: "出票日期",
    },
    {
      prop: "card_source_text",
      label: "来源",
    },
    {
      prop: "mcd_stime",
      label: "开始日期",
      minWidth: 120,
    },
    {
      prop: "mcd_etime",
      label: "结束日期",
      minWidth: 120,
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 200,
      bottons: [
        {
          name: "激活",
          action: "activation",
          type: "customRender",
          className: "theme-font-btn",
        },
        {
          name: "录入通行证",
          action: "registerBtn",
          type: "customRender",
          className: "theme-font-btn",
        },
        {
          name: "使用记录",
          action: "usageRecord",
          type: "customRender",
          className: "theme-font-btn",
        },
      ],
    },
  ]);

  const entryPassRef = ref(null);  // 录入通行证对象
  const activationDialog = ref(null);  // 激活弹框对象
  const s_time = ref('');  // 激活日期
  const showUpload = ref(false);  // 是否显示上传
  const activation_id = ref('');  // 激活会员卡id
  const birthdayCount = ref([]);  // 生日日期

  /**
   *
   * 监听权限
   *
   **/
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /**
   *
   * 激活按钮
   *
   **/
  const onActivation = (row) => {
    activation_id.value = row.mcd_id;
    facialPhotos.value = [];
    srcList.value = [];
    birthdayCount.value = [];
    store.dispatch("getQiniuData");
    getBeforeRegisterInfo();
  }
  const getBeforeRegisterInfo = () => {
    ScenicApi.getBeforeRegisterInfo({ mcd_id: activation_id.value }).then((res) => {
      entryPassRef.value.dialogRef.isLoading = false;
      if (res.Code === 200) {
        // showUpload.value=true;
        s_time.value = res.Data.mcd_stime;
        showUpload.value = res.Data.img_count_data.need_img_num > 0;
        if (res.Data.birthday_count > 0) {
          for (var i = 0; i < res.Data.birthday_count; i++) {
            birthdayCount.value.push({ child_birthday: '' });
          }
        }
        activationDialog.value.show();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   *
   * 确认激活按钮
   *
   **/
  const onConfirmActivation = () => {
    let params = {
      mcd_id: activation_id.value,
      s_time: s_time.value,
      child_img: [],
      child_birthday: [],
    };
    // 处理生日信息
    if (birthdayCount.value.length > 0) {
      for (var i = 0; i < birthdayCount.value.length; i++) {
        if (!birthdayCount.value[i].child_birthday) {
          ElMessage.error("请选择生日日期！");
          return false;
        }
        params.child_birthday.push(birthdayCount.value[i].child_birthday);
      }
    }
    // 处理上传附件
    if (facialPhotos.value.length > 0) {
      facialPhotos.value.forEach(item => {
        params.child_img.push(item.key);
      })
    }
    activationDialog.value.isLoading = true;
    // console.log("params", params)
    ScenicApi.activateMembershipCard(params).then((res) => {
      activationDialog.value.isLoading = false;
      if (res.Code === 200) {
        activationDialog.value.close();
        tableRef.value.tableLoad();
        ElMessage.success(`激活成功！`);
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  const informationObj = ref({
    m_realname: "",
  });
  const isQuick = ref(false);
  const s_id = ref('');  // 快捷录入园区id
  /**
  *
  * 录入按钮
  *
  * */
  const registerBtn = (row) => {
    childrenInfo.value = [];
    adultInfo.value = [];
    fileList.value = [];
    s_id.value = '';
    if (row.mcd_id) {
      isQuick.value = false;
      entryPassRef.value.openDialog(row.mcd_id, 'sale');
    } else {
      // 快捷录入
      isQuick.value = true;
      childrenInfo.value.push(JSON.parse(JSON.stringify(informationObj.value)));
      adultInfo.value.push(JSON.parse(JSON.stringify(informationObj.value)));
      ticketDialog.value.show();
    }
  }
  /**
   * 
   * 录入通行证确定按钮
   * 
   * */
  const determine = (data) => {
    entryPassRef.value.dialogRef.isLoading = true;
    ScenicApi.enterCardReceive(data).then((res) => {
      entryPassRef.value.dialogRef.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success(`录入成功！`);
        entryPassRef.value.closeDialog();
        tableRef.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
  *
  * 录入确定按钮
  *
  * */
  const confirm = () => {
    const parmas = {
      receive_record_child: [],  // 儿童
      receive_record_audit: [],  // 成人
    };
    let repeatArr = [];
    let url = "";
    if (isQuick.value) {
      // 如果是快捷录入
      if (s_id.value == '') {
        // 验证是否选择园区
        ElMessage.error('请选择园区');
        return false;
      }
      parmas.s_id = s_id.value;
      url = "quickEntry";
    } else {
      // 列表录入
      parmas.mcd_id = mcd_id.value;
      url = "cardReceive";
    }
    // 处理儿童信息
    childrenInfo.value.forEach(item => {
      if (item.paper_sn) {
        parmas.receive_record_child.push(item.paper_sn);
        repeatArr.push(item.paper_sn);
      }
    })
    // 处理成人信息
    adultInfo.value.forEach(item => {
      if (item.paper_sn) {
        parmas.receive_record_audit.push(item.paper_sn);
        repeatArr.push(item.paper_sn);
      }
    })
    if (duplicates(repeatArr).length > 0) {
      ElMessage.error('重复录入!');
      return false;
    }
    ticketDialog.value.isLoading = true;
    // console.log("确定按钮", parmas, childrenInfo.value, adultInfo.value)
    ScenicApi[url](parmas).then((res) => {
      ticketDialog.value.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success(`录入成功！`);
        ticketDialog.value.close();
        tableRef.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  const handleSn = (data, index, index2) => {
    childrenBlur();
    if (data == 'children') {
      // 儿童
      if (index2 == childrenInfo.value.length) {
        // 如果当前光标在儿童最后一行，则光标跳成人第一行
        document.getElementById('adult0').focus();
      } else {
        document.getElementById(data + index2).focus();
      }
    } else {
      // 成人
      if (index2 !== adultInfo.value.length) {
        document.getElementById(data + index2).focus();
      }
    }
  }
  /**
  *
  * 快捷录入判断是否重复
  *
  * */
  const childrenBlur = () => {
    let braceletArr = [];
    // 处理儿童信息
    childrenInfo.value.forEach(item => {
      if (item.paper_sn) {
        braceletArr.push(item.paper_sn);
      }
    })
    // 处理成人信息
    adultInfo.value.forEach(item => {
      if (item.paper_sn) {
        braceletArr.push(item.paper_sn);
      }
    })
    if (duplicates(braceletArr).length > 0) {
      ElMessage.error('重复录入!');
      return false;
    }
  }
  /**
  *
  * 新增一行
  *
  * */
  const onAddLineBtn = (type) => {
    if (type == 'children') {
      // 增加儿童
      childrenInfo.value.push(JSON.parse(JSON.stringify(informationObj.value)));
    } else {
      // 增加成人
      adultInfo.value.push(JSON.parse(JSON.stringify(informationObj.value)));
    }
  }
  /**
  *
  * 获取园区选项
  *
  * */
  const getParkDatas = () => {
    BasicApi.getScenicByProject({}).then((res) => {
      if (res.Code === 200) {
        parkOptions.value = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取园区失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
  *
  * 获取类型选项
  *
  * */
  const getNewTicketType = () => {
    ScenicApi.getNewTicketType({}).then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data;
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
  *
  * 获取状态选项
  *
  * */
  const getStatusDatas = () => {
    ScenicApi.getMemberCardStatus({}).then((res) => {
      if (res.Code === 200) {
        filters.value[3].options = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取状态失败！";
        ElMessage.error(msg);
      }
    });
  }

  const faceLoading = ref(false);  // 是否加载
  const faceDialogRef = ref(null);  // 拍照上传对象
  const facialPhotos = ref([]);  // 已上传附件
  const currentCameraIndex = ref(null);
  const srcList = ref([]);  // 预览图片数据
  // 加载上传附件
  function uploadLoading(flag) {
    faceLoading.value = flag;
  }
  // 点击拍照录入
  function openFaceDialog(index) {
    currentCameraIndex.value = index;
    faceDialogRef.value.openDialog();
  }
  // 拍照录入确定按钮
  function handleCamera(data) {
    srcList.value.push(data.standard_url);
    facialPhotos.value.push(data);
    faceDialogRef.value.closeDialog();
  }
  /**
   * 
   * 图片上传
   * 
   * */
  const uploadFile = (obj) => {
    faceLoading.value = false;
    srcList.value.push(obj.standard_url);
    facialPhotos.value.push(obj);
  };
  /**
   * 
   * 图片删除
   * 
   * */
  const deleteFile = (obj, i) => {
    facialPhotos.value.splice(i, 1);
    srcList.value.splice(i, 1);
  };
  const usageRecordRef = ref(null);  // 使用记录对象
  /**
   * 
   * 使用记录按钮
   * 
   * */
  const onUsageRecord = (row) => {
    usageRecordRef.value.openDialog(row);
  }
  /**
   * 
   * 选择会员卡类型获取二级分类
   * 
   * */
  const typeChange = (item) => {
    filters.value[2].value = '';
    tableRef.value.tableLoad();
    if (item.value == '') {
      filters.value[2].options = [];
      return false;
    }
    ScenicApi.getSourceNameByType({ source: '2', type: item.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[2].options = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  }

  onMounted(() => {
    tableRef.value.tableLoad();
    getStatusDatas();
    getParkDatas();
    getNewTicketType();
  });
</script>
<style lang="scss">
  .data-title {
    margin: 10px 0;
    font-weight: bold;
    color: var(--text-color);
  }

  .upload-box {
    margin-bottom: 20px;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .into-face {
    color: #33A8FF;
    cursor: pointer;
    margin: 20px 0;
  }

  .el-autocomplete {
    width: 100%;
  }

  .person_content {
    background-color: #DDF1FF;
    color: #33A8FF;
    margin-top: 10px;
    line-height: 38px;
    padding: 0 10px;
    width: 200px;
  }

  .vip-container {
    font-family: "Source Han Sans CN";

    .content {
      padding: 15px 20px 20px;
    }
  }

  .enter-dialog {
    .el-overlay-dialog {
      overflow: hidden;
    }

    .ticket-content {
      min-height: 100px;
      max-height: 450px;
      overflow-y: auto;
    }

    .el-range-editor .el-range-input {
      background: #f2f2f2;
    }

    .enter-title {
      color: var(--text-color);
      font-size: 14px;
      margin: 10px 0;
    }

    .add-btn {
      text-align: center;
      width: 200px;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      background-color: var(--btn-bg-color);
      color: var(--text-white-color);
      border: 1px solid var(--btn-bg-color);
    }

    .visitor-item {
      padding: 10px 0;
      position: relative;

      .visitor-form {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        .el-form-item__content {
          line-height: 20px;
        }

        .el-form-item__label {
          padding-right: 0;
          user-select: none;
        }

        .el-input__inner {
          padding-right: 10px;
        }

        .el-form-item {
          margin-right: 10px;
          padding: 10px 0;
          border: 2px dashed #eee;
          align-items: center;
          margin-bottom: 8px;

          .el-input__inner {
            height: 20px;
            line-height: 20px;
          }

          .el-form-item__error {
            top: 68%;
            left: 15px;
          }
        }

        .el-form-item__content .el-input__inner,
        .el-form-item__content .el-textarea__inner {
          background-color: transparent;
        }
      }
    }
  }
</style>